.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.home-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 65vh; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
    position: relative;
}

.overlay-home {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and transparency */
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.home-section h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.home-section h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #ddd;
}

.highlights-section {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
    background-color: #ffffff;
}

.highlight {
    flex: 1;
    max-width: 300px;
    margin: 20px;
    text-align: center;
}

.highlight h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.highlight p {
    font-size: 1rem;
    color: #666;
}

@media (max-width: 768px) {
    .home-section h1 {
        font-size: 2rem;
    }

    .home-section h4 {
        font-size: 1.2rem;
    }

    .highlights-section {
        flex-direction: column;
        align-items: center;
    }

    .highlight {
        margin: 10px 0;
    }
}