/* Navbar styles */
.navbar {
  margin-bottom: 0px; /* Adjust the margin-bottom to create space */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  padding: 0px 10px 0px 0px !important;
}

.logo {
  width: 150px;
}

.navbar-toggler {
  background: white;
}

.navbar-collapse {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}

.icon {
  padding: 3px;
}

#page-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px;
}

.pages {
  font-size: 1.4em !important;
  padding: 20px;
  padding-left: 0px;
  color: white;
}

.pages:hover {
  color: #b2967d !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    text-align: center;
    background: black;
    padding: 0px 10px 0px 0px !important;
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-toggler {
    background: white;
  }

  .pages {
    padding: 5px;
  }

  #page-container {
    margin: 10px 0;
  }
}
