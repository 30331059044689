.book-section {
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 20px 20px 180px; /* Adjust padding as needed */
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.overlay-book {
    color: white;
    max-width: 500px; /* Limit the overlay-book width */
    background-color: rgba(0, 0, 0, 0.7); /* Solid black background */
    padding: 20px;
    border-radius: 10px;
}

.overlay-book h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.overlay-book p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.book-button {
    background-color: #fa9a3a; /* Update button background color */
    color: white;
    border: none;
    padding: 12px 24px; /* Update button padding */
    border-radius: 6px; /* Update button border radius */
    font-size: 1.2rem;
    cursor: pointer;
}

.book-button:hover {
    background-color: #ffbb54; /* Update button hover background color */
}

@media (max-width: 768px) {
    .book-section {
        padding: 35px 20px;
    }

    .overlay-book h1 {
        font-size: 2rem;
    }

    .overlay-book p {
        font-size: 1rem;
    }

    .book-button {
        font-size: 1rem;
        padding: 10px 20px; /* Update button padding */
    }
}
