/* rooms.css */

/* Center text in room-description */
.room-description {
    text-align: center;
}

/* Center room-container horizontally */
.scrollable-container {
    padding-top: 0px;
    text-align: center;
    height: 90vh; /* Adjust this height as needed */
    overflow-y: auto;
    background-color: #f0f0f0; /* Add a background color for debugging */
}

.carousel-root.carousel{
    max-width:80vh;
}

.carousel {
    width: 80%; /* Adjust the width of the carousel as needed */
    margin: 0 auto;
}

.carousel-image {
    width: 100%; /* Ensure the images fit within the carousel */
    height: auto;
    max-width: 80vh;
    max-height: 80vh; /* Adjust the max height as needed */
    object-fit: cover; /* Ensure the images maintain their aspect ratio */
}
