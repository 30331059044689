.about-section {
    background-position: center;
    position: relative;
    padding: 50px 20px; /* Adjust padding as needed */
    height: auto; /* Set height to auto to allow content to expand */
    overflow-y: auto; /* Enable vertical scrolling */
}

.overlay {
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.overlay section {
    margin-bottom: 20px;
}

.text{
    color: white;
}

.card{
    background-color: rgba(0, 0, 0, 0.70) !important;
    border-color: rgba(0, 0, 0, 0.70);
}

.overlay h2, .overlay h3 {
    font-size: 1.2rem; /* Adjust font size for h2 and h3 */
    margin-bottom: 10px;
}

.overlay p {
    font-size: 0.9rem; /* Adjust font size for paragraphs */
    color: #ddd;
}

@media (max-width: 768px) {
    .about-section {
        padding: 35px 20px;
    }

    .overlay h2, .overlay h3 {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }

    .overlay p {
        font-size: 0.8rem; /* Adjust font size for smaller screens */
    }
}
