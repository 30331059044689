.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 10px 0;
}

.button-container button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid white;
    background-color: white;
    color:#fa9a3a;
    border-radius: 50px;
}

.button-container button.selected {
    background-color: #fa9a3a;
    color: white;
    border: 1px solid white;
    border-radius: 50px;
}

.button-container button:hover {
    background-color: #fccd9e;
    color: white;
    border: 1px solid white;
    border-radius: 50px;
}

@media (max-width: 768px) {
    .carousel-container {
        padding-top: 15px;
    }

    .navbar {
        padding: 10px;
    }

    .footer {
        text-align: center;
    }

    .button-container {
        flex-direction: column;
        gap: 5px;
    }

    .button-container button {
        width: 100%;
        padding: 10px;
    }
}