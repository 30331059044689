html, body, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.contact-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.contact-container {
  flex-grow: 1; /* Ensures that this container takes up remaining space */
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  overflow-y: auto; /* Enable vertical scrolling */
}

.contact-container h1 {
  margin-bottom: 20px;
  color: #333;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the form in the flex container */
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form .form-group {
  margin-bottom: 15px;
  width: 100%; /* Full width for better responsiveness */
  max-width: 500px; /* Set a max-width so the form controls don't get too wide */
  text-align: left;
}

.contact-form label {
  margin-bottom: 5px;
  color: #666;
  display: block;
  font-weight: bold;
}

.contact-form .form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 0.5em;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.contact-form textarea.form-control {
  height: 150px; /* Adjust the height as needed */
}

.contact-form .btn {
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.contact-form .btn:hover {
  background-color: #b2967d;
}

.contact-form .response-message {
  margin-top: 15px;
  color: green;
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-container {
      padding-left: 10px;
      padding-right: 10px;
  }

  #message{
    height:10vh;
  }

  .contact-form .form-group {
      width: 100%; /* Take full width on smaller screens */
  }

  .contact-form .btn {
      width: 100%; /* Button takes full width on smaller screens */
  }

  .btn{
    margin-bottom: 10vh;
  }
}
