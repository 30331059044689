.carousel-wrapper{
    height: 50vh;
    display: flex;
    justify-content: center;
}

.carousel-control-next-icon{
    background-color: #fa9a3a;
    border-radius: 40%;
}

.carousel-control-prev-icon{
    background-color: #fa9a3a;
    border-radius: 40%;
}

.carousel-inner {
    max-height: auto;
    img {
      max-width: 45vw;
      max-height: auto;
    }
  }
  
  .carousel-indicators img {
    max-width: 100px;
  }