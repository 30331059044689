.footer-container {
    background-color: rgb(48, 48, 48);
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 40px;
}

.footer-content {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
    font-size: 0.7em;
    color: white;
}

.footer-info {
    display: flex;
    align-items: center;
}

.footer-info a {
    margin-left: 10px;
    color: white;
    text-decoration: none;
}

.footer-address {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.footer-phone {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
        font-size: 0.5em;
        color: white;
    }

    .footer-info {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 0;
    }

    .footer-address,
    .footer-phone {
        margin-bottom: 0;
    }

    .footer-address {
        margin-right: 20px;
    }
}
