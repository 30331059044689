/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Apply styles to body and html for preventing scrolling */
body, html {
    overflow: hidden;
}

.amenities-section {
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 40px 20px; /* Adjust padding as needed */
    min-height: 100vh;
    overflow: hidden; /* Disable scrolling */
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and transparency */
    color: white;
    padding: 5%;
    border-radius: 10px;
    overflow-y: scroll;
    height: 85vh;
}

.overlay section {
    margin-bottom: 20px;
}

.overlay h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.overlay p {
    font-size: 1rem;
    color: #ddd;
}

@media (max-width: 768px) {
    .amenities-section {
        padding: 35px 20px;
    }

    .overlay h3 {
        font-size: 1.2rem;
    }

    .overlay p {
        font-size: 0.9rem;
    }
    
    .overlay{
        padding: 5%;
        max-height: 75vh;
    }
}
